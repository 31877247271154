import { createContext, useState, ReactNode } from "react";

type userType = {
  name?: string;
  email?: string;
  picture?: string;
  role?: string;
};

interface AuthContextProviderProps {
  children: ReactNode;
}

interface ContextState {
  user: userType;
  setUser: (user: {}) => void;
}
export const AuthContext = createContext({} as ContextState);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [user, setUser] = useState({});
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
