import sortAndFilter from "../../../utils/sortAndFilter";
import UpDownArrow from "../../../images/right-left-solid.svg";
import styles from "./SortBy.module.css";

function SortByDate(props: any) {
  let { groups, setGroups, reverseDate, setReverseDate, setReverseName } =
    props.props;

  function handleChangeDate() {
    setGroups(sortAndFilter("End date", groups, reverseDate));
    setReverseDate(!reverseDate);
    setReverseName(false);
  }

  return (
    <div className={styles.buttonContainer} id={styles.dateSorting}>
      <button className={styles.sortButton} onClick={handleChangeDate}>
        <img src={UpDownArrow} alt="pil" className={styles.rotateArrow} />
      </button>
    </div>
  );
}

export default SortByDate;
