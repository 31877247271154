import styles from "./RightArrows.module.css";
import rightarrow from "../../../images/angle-right-solid.svg";
import dubblerightarrow from "../../../images/angles-right-solid.svg";
import { addMonths, startOfMonth } from "date-fns/esm";

function RightArrows(props: any) {
  const { visibleStart, visibleEnd, setVisibleStart, setVisibleEnd } =
    props.props;

  const handleMonthClick = (type: string, length: number) => {
    if (type === "add") {
      setVisibleStart(addMonths(startOfMonth(visibleStart), length));
      setVisibleEnd(addMonths(startOfMonth(visibleEnd), length));
    }
  };
  return (
    <div style={props.styleProps} className={styles.rightArrowsContainer}>
      <div className={styles.nextButton}>
        <button
          className={styles.button}
          id={styles.arrowRight}
          onClick={() => handleMonthClick("add", 1)}
          value={1}
        >
          <img className={styles.images} src={rightarrow} alt="pil" />
        </button>
        <button
          className={styles.button}
          id={styles.dubbleArrowRight}
          onClick={() => handleMonthClick("add", 12)}
          value={12}
        >
          <img className={styles.images} src={dubblerightarrow} alt="pil" />
        </button>
      </div>
    </div>
  );
}

export default RightArrows;
