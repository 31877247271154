import { useState, useEffect, useRef, useContext, ChangeEvent } from "react";
import styles from "./Modal.module.css";
import { format } from "date-fns";
import routes from "../../services/service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../../context/AuthContext";

export default function Modal(props: any) {
  const {
    modalItem,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setLoading,
    onClose,
    show,
  } = props;
  const date = new Date();
  const infoButtonRef = useRef<HTMLButtonElement>(null);
  const { user } = useContext(AuthContext);
  const [customEndDate, setCustomEndDate] = useState(date);
  const [customTitle, setCustomTitle] = useState("");
  const [customType, setCustomType] = useState("");

  const [isDisabled, setIsDisabled] = useState(true),
    [isChecked, setIsChecked] = useState(false),
    [dateDisabled, setDateDisabled] = useState(false),
    [isVisible, setIsVisible] = useState(false),
    [isSubmitted, setIsSubmitted] = useState(false),
    [areYouSure, setAreYouSure] = useState(false),
    [nothingChanged, setNothingChanged] = useState(false);

  const selectedEndDate =
    endDate && !isNaN(new Date(endDate).getTime()) ? new Date(endDate) : date;

  const selectedStartDate =
    startDate && !isNaN(new Date(startDate).getTime())
      ? new Date(startDate)
      : date;

  useEffect(() => {
    setCustomEndDate(endDate);
  }, [endDate]);

  useEffect(() => {
    if (modalItem) {
      setStartDate(new Date(modalItem?.start_time));
      setEndDate(new Date(modalItem?.end_time));
      setIsChecked(false);
      setIsDisabled(true);
      setIsVisible(false);
      setIsSubmitted(false);
      setAreYouSure(false);
      setNothingChanged(false);
      if (modalItem?.customAbsence) {
        setCustomTitle(modalItem.project.customTitle);
        setCustomType(modalItem.project.customType);
      } else {
        setCustomType("lead");
      }
      if (modalItem.blikkItem) {
        setIsDisabled(false);
        document.getElementById("startDate")?.classList.add("disabled");
      }
    }
  }, [show]);

  useEffect(() => {
    if (isChecked) {
      setDateDisabled(true);
      setEndDate(null);
    } else {
      setDateDisabled(false);
      setEndDate(endDate);
    }
  }, [isChecked]);

  if (!props.show) {
    return null;
  }

  function handleDescriptionChange(event: ChangeEvent<HTMLInputElement>) {
    setCustomTitle(event.target.value);
  }

  function handlecustomTypeChange(event: ChangeEvent<HTMLSelectElement>) {
    setCustomType(event.target.value);
  }

  function handleCheckbox() {
    setIsChecked(!isChecked);
  }

  async function submitCustomItem() {
    let newAbsence = {
      startDate: format(selectedStartDate, "yyyy-MM-dd"),
      endDate: format(selectedEndDate, "yyyy-MM-dd"),
      customTitle: customTitle,
      customType: customType,
    };
    if (
      customTitle !== "" &&
      startDate !== null &&
      endDate !== null &&
      startDate < endDate
    ) {
      const response = await routes.addAbsence(newAbsence, modalItem.id);
      if (response.success === true) {
        setLoading(true);
        onClose();
      }
    } else {
      setIsSubmitted(true);
    }
  }

  async function editCustomItem() {
    let newAbsence = {
      startDate: format(selectedStartDate, "yyyy-MM-dd"),
      endDate: format(selectedEndDate, "yyyy-MM-dd"),
      customTitle: customTitle,
      customType: customType,
    };

    if (
      customTitle === modalItem?.project.customTitle &&
      customType === modalItem?.project.customType &&
      startDate.valueOf() === new Date(modalItem?.start_time).valueOf() &&
      endDate.valueOf() === new Date(modalItem?.end_time).valueOf()
    ) {
      setNothingChanged(true);
    } else if (
      customTitle !== "" &&
      startDate !== null &&
      endDate !== null &&
      startDate < endDate
    ) {
      const response = await routes.updateAbsence(
        newAbsence,
        modalItem.project,
        modalItem.group
      );
      if (response.success === true) {
        setLoading(true);
        onClose();
      }
    } else {
      setIsSubmitted(true);
    }
  }

  async function updateEndDate() {
    if (dateDisabled) {
      await routes.addNewEndDate({
        id: modalItem.group,
        customEndDate: null,
        oldProject: modalItem.project,
      });
      setLoading(true);

      onClose();
    }
    if (!dateDisabled) {
      if (new Date(modalItem?.end_time).valueOf() === customEndDate.valueOf()) {
        setNothingChanged(true);
      } else if (
        modalItem?.end_time &&
        new Date(modalItem.end_time).setHours(0, 0, 0, 0) !==
          customEndDate.setHours(0, 0, 0, 0)
      ) {
        const response = await routes.addNewEndDate({
          id: modalItem.group,
          customEndDate: format(customEndDate, "yyyy-MM-dd"),
          oldProject: modalItem.project,
        });

        if ((response.success = true)) {
          setLoading(true);
          onClose();
        } else {
          console.log(response);
        }
      }
    }
  }

  async function showAreYouSure() {
    setAreYouSure(true);
  }

  async function removeCustomItem() {
    if (areYouSure) {
      const response = await routes.removeAbsence(
        modalItem.project,
        modalItem.group
      );
      if (response.success === true) {
        setLoading(true);
        onClose();
      }
    }
  }

  function showCompanyInformation() {
    if (infoButtonRef.current?.innerHTML === "Mer info") {
      infoButtonRef.current.innerHTML = "Visa mindre";
      infoButtonRef.current.classList.add(styles.rotate);
    } else if (infoButtonRef.current?.innerHTML === "Visa mindre") {
      infoButtonRef.current.innerHTML = "Mer info";
      infoButtonRef.current.classList.remove(styles.rotate);
    }
    if (modalItem?.location === undefined) {
      modalItem.location = "Ingen information";
    }
    setIsVisible(!isVisible);
  }

  if (dateDisabled) {
    document.getElementById("endDateColorChange")?.classList.add("disabled");
  }

  return (
    <div className={styles.modal} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.closeDiv}></div>
        <>
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              <p>{modalItem.title}</p>
              <button onClick={onClose} className={styles.closeButtonX}>
                X
              </button>
            </div>
          </div>

          <div className={styles.modalBody}>
            {user.role === "admin" ? (
              <>
                {modalItem?.newItem || modalItem?.customAbsence ? (
                  <div>
                    <label>
                      <p className="label-text">Anledning:</p>
                      <select
                        name="itemSelect"
                        id="itemSelect"
                        className={styles.typeSelect}
                        onChange={handlecustomTypeChange}
                        defaultValue={
                          modalItem?.customAbsence
                            ? modalItem?.project.customType
                            : "lead"
                        }
                      >
                        <option value="lead">Potentiellt uppdrag</option>
                        <option value="absence">Frånvaro / Ledighet</option>
                      </select>
                    </label>
                    <label className={styles.titleInputLabel}>
                      <p className="label-text">Titel:</p>
                      <input
                        type="text"
                        className={styles.titleInput}
                        placeholder={
                          customType === "absence"
                            ? "Typ av frånvaro"
                            : "Namn på kund/uppdrag"
                        }
                        onChange={handleDescriptionChange}
                        defaultValue={
                          modalItem?.customAbsence
                            ? modalItem?.project.customTitle
                            : ""
                        }
                      />
                    </label>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            <p className={styles.inputWarningText}>
              {(customTitle === "" || undefined) && isSubmitted
                ? "Skriv något i fältet ovan"
                : nothingChanged
                ? "Inget har ändrats"
                : ""}
            </p>
            <div className={styles.datePickersDiv}>
              <div className={styles.datePickerDiv}>
                <label id="startDate" className={styles.datePickerStartDate}>
                  <p className="label-text">Startdatum:</p>
                  <DatePicker
                    id="startDate"
                    className={styles.datePickerStart}
                    minDate={new Date("2021-05-01")}
                    maxDate={new Date("2200-12-30")}
                    dateFormat="yyyy/MM/dd"
                    selected={new Date(selectedStartDate)}
                    onChange={(date: Date) => setStartDate(date)}
                    calendarStartDay={1}
                    disabled={user.role === "admin" ? !isDisabled : true}
                  />
                </label>
              </div>

              <p className={styles.datePickerSeparator}>-</p>

              <div className={styles.datePickerDiv}>
                <label
                  className={styles.datePickerEndDate}
                  id="endDateColorChange"
                >
                  <p className="label-text">Slutdatum:</p>
                  <DatePicker
                    className={styles.datePickerEnd}
                    minDate={new Date("2021-05-02")}
                    maxDate={new Date("2200-12-31")}
                    dateFormat="yyyy/MM/dd"
                    disabled={user.role === "admin" ? dateDisabled : true}
                    placeholderText={dateDisabled ? "Inget slutdatum" : ""}
                    selected={dateDisabled ? null : new Date(selectedEndDate)}
                    onChange={(date: Date) => setEndDate(date)}
                    calendarStartDay={1}
                  />
                  {user.role === "admin" &&
                  !modalItem?.newItem &&
                  !modalItem?.customAbsence ? (
                    <label className={styles.checkboxLabel}>
                      Ta bort slutdatum
                      <input
                        type="checkbox"
                        className={styles.checkbox}
                        onChange={handleCheckbox}
                      />
                    </label>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>

            {modalItem?.customAbsence ? (
              ""
            ) : (
              <>
                <section className={styles.moreInfoSection}>
                  {modalItem.blikkItem ? (
                    <button
                      ref={infoButtonRef}
                      onClick={showCompanyInformation}
                      className={styles.infoButton}
                    >
                      Mer info
                    </button>
                  ) : (
                    ""
                  )}
                  {isVisible && (
                    <div className={styles.companyInformation}>
                      <p className={styles.companyInformation}>
                        Titel: {modalItem.project.title}
                      </p>
                      {modalItem.project.location?.city ? (
                        <p className={styles.companyInformation}>
                          Stad: {modalItem.project.location?.city}
                        </p>
                      ) : (
                        ""
                      )}
                      {modalItem.project.location?.postalCode ? (
                        <p className={styles.companyInformation}>
                          Postnummer: {modalItem.project.location?.postalCode}
                        </p>
                      ) : (
                        ""
                      )}
                      {modalItem.project.location?.streetAddress ? (
                        <p className={styles.companyInformation}>
                          Gata: {modalItem.project.location?.streetAddress}
                        </p>
                      ) : (
                        ""
                      )}
                      {modalItem.project.customer?.name ? (
                        <p className={styles.companyInformation}>
                          Uppdragsgivare: {modalItem.project.customer?.name}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </section>
              </>
            )}
          </div>
        </>

        <div className={styles.modalFooter}>
          {areYouSure ? (
            <p className={styles.areYouSureText}>Är du säker?</p>
          ) : (
            ""
          )}
          {areYouSure ? (
            <div className={styles.buttonsDiv}>
              <button
                className={styles.removeButtonConfirmNo}
                onClick={() => {
                  setAreYouSure(false);
                }}
              >
                Nej
              </button>
              <button
                className={styles.removeButtonConfirmYes}
                onClick={removeCustomItem}
              >
                Ja
              </button>
            </div>
          ) : (
            <>
              {modalItem?.customAbsence && user.role === "admin" ? (
                <div className={styles.removeButtonDiv}>
                  <button
                    className={styles.removeButton}
                    onClick={showAreYouSure}
                  >
                    Ta bort
                  </button>
                </div>
              ) : (
                ""
              )}
              {user.role === "admin" ? (
                <div className={styles.buttonsDiv}>
                  <button onClick={onClose} className={styles.closeButton}>
                    Stäng
                  </button>
                  <button
                    className={styles.saveButton}
                    onClick={
                      modalItem?.newItem
                        ? submitCustomItem
                        : modalItem?.customAbsence
                        ? editCustomItem
                        : updateEndDate
                    }
                  >
                    Spara
                  </button>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
