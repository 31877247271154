import { isValid } from "date-fns";

const sortAndFilter = (by: string, data: any, reverse: Boolean) => {
  function reverseSorting(sortOrder: number) {
    return (a: number, b: number) => {
      return sortOrder * (a > b ? -1 : 1);
    };
  }

  let sorted = [...data];

  if (by === "Name") {
    sorted.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  }

  if (by === "End date") {
    sorted.sort((a, b) => {
      if (isValid(new Date(a.rightTitle))) {
        return new Date(a.rightTitle) > new Date(b.rightTitle) ? 1 : -1;
      }
      return 0;
    });
  }

  if (by === "Start date") {
    sorted.forEach((user) => {
      user.absence.sort(
        (a: { [key: string]: Date }, b: { [key: string]: Date }) => {
          return a.startDate > b.startDate ? -1 : 1;
        }
      );
    });
  }

  if (by === "Company") {
    sorted.sort((a, b) => {
      return a.currentWorkPlace > b.currentWorkPlace ? 1 : -1;
    });
  }

  sorted.sort(reverseSorting(reverse ? -1 : 1));

  return sorted;
};

export default sortAndFilter;
