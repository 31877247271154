import { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import routes from "../services/service";

const useFetch = () => {
  const { setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const loginWithToken = async () => {
    if (!document.cookie.includes("stayLoggedIn=true")) return;
    try {
      setLoading(true);
      const data = await routes.loginWithToken();
      setUser(data);
    } catch (error) {
      setError("Something went wrong");
    }
    setLoading(false);
  };

  const handleGoogle = async (response: { credential: string }) => {
    setLoading(true);
    const data = await routes.loginUser(response.credential);
    setLoading(false);
    if (data?.success === true) {
      setUser(data);
    } else {
      setError(data.message);
    }
  };

  return { loading, error, handleGoogle, loginWithToken };
};

export default useFetch;
