import React from "react";
import { projectObject } from "../../../utils/types";
import styles from "./CustomerModal.module.css";

export default function CustomerModal(props: any) {
  const projects: projectObject[] = props.projects;
  const showCustomerModal = props.customerModal.showCustomerModal;
  const setShowCustomerModal = props.customerModal.setShowCustomerModal;

  const uniqueWorkplaces = getWorkplaceAndEmployee(projects);

  function getWorkplaceAndEmployee(projects: projectObject[]) {
    const workplaces: {
      workplace: string;
      customer: string;
      project: projectObject;
    }[] = [];

    projects.forEach((project: any) => {
      const workplace = project.title.split(" - ")[0];
      const customer = project.customer.name;
      workplaces.push({ workplace, customer, project });
    });

    const uniqueWorkplaces = Array.from(
      new Set(workplaces.map((w: any) => w.workplace))
    ).map((workplace) => {
      const workplaceProjects = workplaces.filter(
        (w: any) => w.workplace === workplace
      );
      const status = workplaceProjects.some(
        (p: any) => p.project.status.name === "Pågående"
      )
        ? "Pågående"
        : "Avslutad";
      const customer = workplaceProjects[0].customer;
      return { workplace, customer, status };
    });

    return uniqueWorkplaces;
  }

  function closeModal() {
    setShowCustomerModal(false);
  }

  return (
    <div>
      {showCustomerModal ? (
        <div className={styles.customerModalContainer} onClick={closeModal}>
          <div
            className={styles.modalContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.titleAndClose}>
              <h1 className={styles.headerCustomerTitle}>Kundlista</h1>
              <button className={styles.closeButton} onClick={closeModal}>
                X
              </button>
            </div>
            <div className={styles.customerList}>
              {uniqueWorkplaces.map((workplace: any) => (
                <div key={workplace.workplace}>
                  <p className={styles.line}></p>
                  <h3>{workplace.workplace}</h3>

                  <p className={styles.customerInModal}>
                    Uppdragsgivare: {workplace.customer}
                  </p>
                  <p className={styles.statusInModal}>
                    Status: {workplace.status}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
