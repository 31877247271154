import styles from "./Home.module.css";
import useFetch from "../../hooks/useFetch";
import Header from "../../layout/Header/Header";
import GanttCalendar from "../../components/Calendar/GanttCalendar";

import { useRef, useState } from "react";

function Home() {
  const { loading } = useFetch();
  const [calendarLoading, setCalendarLoading] = useState(true);
  const arrowRef = useRef<HTMLParagraphElement>(null);

  return (
    <div className={styles.homeSection}>
      <Header setCalendarLoading={setCalendarLoading} />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <section
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <GanttCalendar
              calendarLoading={calendarLoading}
              setCalendarLoading={setCalendarLoading}
            />
          </section>
          <p ref={arrowRef} className={styles.downArrow}>
            ⬇
          </p>
        </>
      )}
    </div>
  );
}

export default Home;
