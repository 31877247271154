import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
});

type absence = {
  startDate: string | Date;
  endDate: string | Date;
  type: string;
};

const routes: {
  [key: string]: any;
} = {};

// User routes
routes.loginWithToken = async () => {
  const response = await apiClient.get("/user/loginWithToken");
  return response.data;
};

routes.loginUser = async (token: string) => {
  const tokenData = JSON.stringify({ token: token });
  const response = await apiClient.post("/user/loginUser", tokenData);
  return response.data;
};

routes.logout = async () => {
  const response = await apiClient.get("/user/logout");
  return response.data;
};

// Mongodb routes
routes.getAllUsers = async () => {
  const response = await apiClient.get("/mongo/getAllUsers");
  return response.data;
};

routes.removeAbsence = async (absence: absence, user: string) => {
  const response = await apiClient.post("/mongo/removeAbsence", {
    absence,
    user,
  });
  return response.data;
};

routes.updateAbsence = async (
  newAbsence: absence,
  oldAbsence: absence,
  id: string
) => {
  const response = await apiClient.post("/mongo/updateAbsence", {
    newAbsence,
    oldAbsence,
    id,
  });
  return response.data;
};

routes.addBlikkData = async (data: any) => {
  const response = await apiClient.post("/mongo/addBlikkData", data);
  return response.data;
};

routes.getAllBlikkData = async () => {
  const response = await apiClient.get("/mongo/getAllBlikkData");
  return response.data;
};

routes.getAllPastEmployees = async () => {
  const response = await apiClient.get("/mongo/getAllPastEmployees");
  return response.data;
};

routes.addNewEndDate = async (data: any) => {
  const response = await apiClient.post("/mongo/addNewEndDate", data);
  return response.data;
};

routes.addAbsence = async (data: any, id: any) => {
  const response = await apiClient.post("/mongo/addAbsence", { data, id });
  return response.data;
};

routes.getAllProjects = async () => {
  const response = await apiClient.get("/mongo/getAllProjects");
  return response.data;
};

//Blikk routes
routes.getAllBlikkProjects = async () => {
  const response = await apiClient.get("/blikk/getAllBlikkProjects");
  return response.data;
};

// backend 2
routes.updateMongo = async () => {
  const response = await apiClient.get("/api/updateMongo");
  return response.data;
};

export default routes;
