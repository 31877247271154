import { addMinutes, addMonths } from "date-fns";
import sortAndFilter from "../../utils/sortAndFilter";
import routes from "../../services/service";
import {
  groupObjects,
  itemObjects,
  tempGroupProps,
  projectObject,
} from "../../utils/types";
import { itemColor } from "../../utils/utils";

type toggleModalProps = (thisItem: itemObjects) => void;

export const mapBlikk = async (toggleModal: toggleModalProps) => {
  const date = new Date();
  const [blikkData, getAllBlikkProjects, mongoUsers, pastEmployees] =
    await Promise.all([
      routes.getAllBlikkData(),
      routes.getAllProjects(),
      routes.getAllUsers(),
      routes.getAllPastEmployees(),
    ]);

  blikkData.push(...pastEmployees);

  type objectType = {
    items: itemObjects[];
    groups: groupObjects[];
    projects: projectObject[];
  };

  let itemArray: itemObjects[] = [];
  let groupArray: groupObjects[] = [];

  blikkData?.forEach((user: any) => {
    if (user.id === undefined || user.id === null) return null;

    /* Custom absences */
    user?.absences?.forEach((absence: any) => {
      const { color, isActive } = itemColor(absence);

      let tempItem: itemObjects = {
        id: absence.startDate + absence.endDate + user._id,
        group: user._id,
        title: absence.customTitle,
        start_time: new Date(absence.startDate).valueOf(),
        end_time: new Date(absence.endDate).valueOf(),
        isActive: isActive,
        customAbsence: true,
        className: "item",
        project: absence,
        itemProps: {
          onMouseUp: (thisItem: itemObjects) => {
            toggleModal((thisItem = tempItem));
          },
          onTouchEnd: (thisItem: itemObjects) => {
            toggleModal((thisItem = tempItem));
          },
          style: {
            background: color,
            border: "0",
            color: "white",
            borderRadius: "10px",
          },
        },
      };
      if (isActive) {
        tempItem.itemProps.style.fontWeight = "bold";
      }
      itemArray.push(tempItem);
    });

    /* All Blikk projects */
    getAllBlikkProjects?.forEach((project: projectObject) => {
      if (
        project.title
          ?.toLowerCase()
          .includes(
            user.firstName.toLowerCase() + " " + user.lastName.toLowerCase()
          ) &&
        project.status.isCompletedStatus === false
      ) {
        let sameProject = user.projects?.findIndex(
          (userProject: projectObject) => userProject.id === project.id
        );
        if (sameProject === -1) {
          user.projects.push(project);
        }
      }
    });
    let fullName: string = user.firstName + " " + user.lastName;
    if (fullName.split(" ").length > 2) {
      // the anton problem
      const nameParts = fullName.split(" ");
      fullName = nameParts[0] + " " + nameParts[nameParts.length - 1];
    }

    let picture = mongoUsers.find(
      (mongoUser: any) =>
        mongoUser.firstName === user.firstName &&
        mongoUser.lastName === user.lastName
    )?.picture;

    let tempGroup: tempGroupProps = {
      id: user._id,
      title: fullName,
      rightTitle: 0,
      firstName: user.firstName,
      lastName: user.lastName,
      picture: picture,
      department:
        user.department?.name === undefined ? "Overhead" : user.department.name,
      currentWorkPlace: null,
      stillWorking: user.stillWorking,
      projects: user?.projects,
    };

    if (user.projects?.length === 0 || user.projects === undefined) {
      groupArray.push(tempGroup);
      return null;
    }

    user.projects.forEach((project: projectObject) => {
      const start_Date =
        project.firstDate === undefined
          ? new Date(project.startDate).valueOf()
          : new Date(project.firstDate).valueOf();

      let end_Date = project.endDate
        ? new Date(project.endDate).valueOf()
        : project.lastDate
        ? new Date(project.lastDate).valueOf()
        : addMonths(new Date(project.startDate).valueOf(), 12);

      if (project?.customEndDate) {
        end_Date = new Date(project.customEndDate).valueOf();
      }

      const { title } = project;
      let workplace, employee;
      try {
        [workplace, employee] = title
          .split("-")
          .map((element) => element.trim());
      } catch (e) {
        return null;
      }

      /* TODO: need some checks so the app don't break if its junk data */
      const { color, isActive } = itemColor(project);

      let timelineItem: itemObjects = {
        id: project.id + project.title + user._id,
        group: user._id,
        title: workplace,
        start_time: start_Date,
        end_time: end_Date,
        custom_start_date: null,
        custom_end_date: null,
        isActive: isActive,
        className: "item",
        project: project,
        blikkItem: true,
        itemProps: {
          onMouseUp: (thisItem: itemObjects) => {
            toggleModal((thisItem = timelineItem));
          },
          onTouchEnd: (thisItem: itemObjects) => {
            toggleModal((thisItem = timelineItem));
          },
          style: {
            background: color,
            border: "0",
            color: "white",
            borderRadius: "10px",
          },
        },
      };

      if (isActive) {
        timelineItem.itemProps.style.fontWeight = "bold";
        if (
          project.endDate === null &&
          (project.customEndDate === undefined ||
            project.customEndDate === null)
        ) {
          let tempItemLastSixMonth = { ...timelineItem };

          // adding one minute to the start time to fix a rounding error sometimes causing the item to overlap with the previous item
          tempItemLastSixMonth.start_time = addMinutes(
            addMonths(date, 1),
            1
          ).valueOf();
          tempItemLastSixMonth.end_time = addMonths(date, 36).valueOf();
          tempItemLastSixMonth.id = project.id + user._id + 10000;

          tempItemLastSixMonth.itemProps = {
            onMouseUp: (thisItem: itemObjects) => {
              toggleModal((thisItem = timelineItem));
            },
            onTouchEnd: (thisItem: itemObjects) => {
              toggleModal((thisItem = timelineItem));
            },
            style: {
              fontSize: "0px",
              background:
                "repeating-linear-gradient(45deg, #06784D 15px, #06784D 16px, #0ABF7B 1px, #0ABF7B 60px)",
              border: "0",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              color: "black",
            },
          };

          if (!project.customEndDate) {
            itemArray.push(tempItemLastSixMonth);
            timelineItem.itemProps.style.background = "#0ABF7B";
            timelineItem.itemProps.style.borderTopRightRadius = "0";
            timelineItem.itemProps.style.borderBottomRightRadius = "0";
            timelineItem.end_time = addMonths(date, 1).valueOf();
            tempGroup.rightTitle = "  ";
          }
        } else if (project.customEndDate) {
          timelineItem.end_time = new Date(project.customEndDate);
          tempGroup.rightTitle = end_Date;
        } else {
          timelineItem.end_time = new Date(project.endDate).valueOf();
          tempGroup.rightTitle = end_Date;
        }
      } else {
        timelineItem.itemProps.style.color = "#dfdfdf";
        timelineItem.itemProps.style.opacity = "0.9";
      }

      if (project.customEndDate) {
        timelineItem.title = timelineItem.title + " *";
      }

      itemArray.push(timelineItem);
    });

    groupArray.push(tempGroup);
  });

  itemArray.sort((a, b) => {
    return a.end_time > b.end_time ? -1 : 1;
  });

  // set current workplace for sorting purposes, still needs work if there is
  groupArray.forEach((user, index) => {
    itemArray.find((element) =>
      element.group === user.id && element.isActive && element.title !== "  "
        ? (groupArray[index]["currentWorkPlace"] = element.title)
        : null
    );
  });

  const object: objectType = {
    items: itemArray,
    groups: sortAndFilter("End date", groupArray, false),
    projects: getAllBlikkProjects,
  };

  return object;
};
