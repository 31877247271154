import styles from "./Footer.module.css";

function Footer() {
  return (
    <nav className={styles.footerContainer}>
      <div className={styles.smallSection}>
        <p>Full Stack Development</p>
        <p>& UX Design</p>
      </div>
      <div className={styles.smallSection}>
        <p>Lindholmspiren 7A</p>
        <p>417 56 Gothenburg</p>
      </div>
      <div className={styles.smallSection}>
        <a href="mailto:hello@uptive.se">hello@uptive.se</a>
        <a href="tel:+46706871008">+46 706 871 008</a>
      </div>
      <div className={styles.smallSection}>
        <a href="https://www.instagram.com/teamuptive/">Instagram</a>
        <a href="https://www.linkedin.com/company/uptive-ab/">Linkedin</a>
      </div>
      <div className={styles.bigSection}>
        <img
          className={styles.logo}
          src="/Logo_White_utan-marginal.png"
          alt="Logo"
        />
      </div>
    </nav>
  );
}

export default Footer;
