import { addMonths, differenceInHours } from "date-fns";

let date = new Date();

type itemColorProps = {
  color: string;
  isActive: boolean;
};

export const itemColor = (project: any): itemColorProps => {
  if (
    differenceInHours(new Date(project.lastDate), addMonths(date, -2)) <= 0 ||
    (project.endDate !== null &&
      differenceInHours(new Date(project.endDate), date) <= 0) ||
    (project.customEndDate && new Date(project.customEndDate) < date)
  ) {
    return { color: "#5E5E5E", isActive: false };
  }
  if (project.customType === "absence") {
    return { color: "#925DD8", isActive: true };
  }
  if (project.customType === "lead") {
    return { color: "#ee964b", isActive: true };
  }
  return { color: "#0ABF7B", isActive: true };
};
