import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import useFetch from "./hooks/useFetch";
import "./App.css";
import Login from "./views/login/Login";
import Home from "./views/home/Home";

function App() {
  const { user } = useContext(AuthContext);
  const { error } = useFetch();

  useEffect(() => {
    if (error !== "") {
      console.log(error);
    }
  }, [error]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={!user?.email ? <Login /> : <Navigate to="/home" />}
        />
        <Route
          path="/home"
          element={user?.email ? <Home /> : <Navigate to="/" />}
        />
        <Route
          path="*"
          element={user?.email ? <Navigate to="/home" /> : <Navigate to="/" />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
