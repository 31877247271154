import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import styles from "./searchBar.module.css";
import { tempGroupProps, projectObject } from "../../../utils/types";
import Zoom from "./Zoom";
import addressBook from "../../../images/address-book-solid.svg";
import { AuthContext } from "../../../context/AuthContext";
import Legend from "./Legend";

function SearchBar(props: any) {
  let {
    setGroups,
    unfilteredGroups,
    setVisibleStart,
    setVisibleEnd,
    zoomCount,
    setZoomCount,
    setMonthZoom,
    visibleEnd,
    visibleStart,
    showModal,
  } = props.props;

  const { user } = useContext(AuthContext);

  const { setShowCustomerModal, showCustomerModal } = props.customModal;
  const [search, setSearch] = useState("");
  const [department, setDepartment] = useState<string[]>(["UX", "Dev"]);

  const findUsers = (search: string) => {
    let searchValue = [...unfilteredGroups];

    //the Henrik problem. Making sure that he shows in the dev group in the calendar.
    searchValue = searchValue.map((user) => {
      if (user.firstName === "Henrik" && user.lastName === "Vestgöte") {
        return { ...user, department: "Dev" };
      }
      return user;
    });

    searchValue = searchValue.map((user) => {
      if (user.stillWorking === false) {
        return { ...user, department: "exemployee" };
      }
      return user;
    });

    if (department.length > 0) {
      searchValue = searchValue.filter((user: tempGroupProps) =>
        department.includes(user.department)
      );
    }

    const filteredUsers = searchValue.filter((user: tempGroupProps) => {
      return user.title.toLowerCase().includes(search.toLowerCase());
    });

    const filteredCompany = searchValue.filter((user: any) => {
      if (user.projects === undefined) {
        return null;
      }
      return user.projects.some((project: projectObject) =>
        project.title
          .split("-")[0]
          .trim()
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    });
    const filterReturn = [...filteredUsers, ...filteredCompany].filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    setGroups(filterReturn);
  };

  useEffect(() => {
    findUsers(search);
  }, [search, department]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  let options = [];

  if (user?.role === "admin") {
    options = [
      { value: "UX", label: "UX" },
      { value: "Dev", label: "Dev" },
      { value: "UK", label: "Underkonsult" },
      { value: "Overhead", label: "Overhead" },
      { value: "exemployee", label: "Tidigare anställda" },
    ];
  } else {
    options = [
      { value: "UX", label: "UX" },
      { value: "Dev", label: "Dev" },
      { value: "Overhead", label: "Overhead" },
    ];
  }

  if (department.length === 0) {
    setDepartment([""]);
  }

  return (
    <div className={styles.searchContainer}>
      <div className={styles.search}>
        <input
          className={styles.searchBarInput}
          type="text"
          placeholder="Sök"
          value={search}
          onChange={handleSearch}
        />
      </div>
      <div className={styles.departmentSelector}>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              height: "25px",
              alignContent: "center",
              maxHeight: "25px",
              minHeight: "25px",
              borderRadius: "5px",
              border: "none",
              padding: "1px",
              backgroundColor: "rgb(65, 65, 65)",
              color: "white",
            }),
            menu: (base, state) => ({
              ...base,
              border: "none",
              paddingTop: "0px",
              paddingBottom: "0px",
              marginTop: "0px",
              marginBottom: "0px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
            }),
          }}
          isClearable={false}
          isSearchable={false}
          placeholder="Avdelning"
          isMulti
          options={options}
          value={options.filter((option) => department.includes(option.value))}
          onChange={(value) => {
            setDepartment(value.map((option: any) => option.value));
          }}
          classNamePrefix="react-select"
        />
      </div>
      <div className={styles.legendDiv}>
        <Legend />
      </div>
      <button
        className={styles.customerModalButton}
        onClick={() => setShowCustomerModal(!showCustomerModal)}
      >
        <img
          className={styles.customerModalIcon}
          src={addressBook}
          alt="Kunder"
        ></img>
      </button>
      <div className={styles.zoomContainerSearchBar}>
        <Zoom
          props={{
            setVisibleStart,
            setVisibleEnd,
            visibleEnd,
            visibleStart,
            zoomCount,
            setZoomCount,
            setMonthZoom,
            showModal,
            showCustomerModal,
          }}
        />
      </div>
    </div>
  );
}

export default SearchBar;
