import React, { useEffect, useRef } from "react";
import useFetch from "../hooks/useFetch";

function GoogleInit() {
  const { handleGoogle, loginWithToken } = useFetch();
  const googleLoginDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loginWithToken();
    if (googleLoginDiv.current) {
      google.accounts.id.initialize({
        // client_id: process.env.REACT_APP_GOOGLE_ID,
        client_id:
          "509250309725-3vj8n37alf5bk7dt0g9vg3621ig43g1c.apps.googleusercontent.com",
        auto_select: true,
        itp_support: true,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(googleLoginDiv.current, {
        theme: "filled_black",
        shape: "pill",
        scope: "profile email",
        dataText: "Sign in with Google",
        width: 280,
        longTitle: true,
        onFailure: onFailure,
      });
    }
  }, [googleLoginDiv.current]);

  function onFailure(error: string) {
    console.log(error);
  }
  return <div className="login" id="myDiv" ref={googleLoginDiv}></div>;
}

export default GoogleInit;
