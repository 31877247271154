import { AuthContext } from "../../context/AuthContext";
import { useContext, useState, useEffect, useRef } from "react";
import routes from "../../services/service";
import styles from "./Header.module.css";
import gifWhite from "../../images/gif_WhiteNoBG.gif";

function Header(props: { setCalendarLoading: (arg0: boolean) => void }) {
  const { setCalendarLoading } = props;
  const gifRef = useRef<HTMLImageElement>(null);
  const adminText = useRef<HTMLParagraphElement>(null);
  const { user, setUser } = useContext(AuthContext);
  const [showDiv, setShowDiv] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowDiv(true);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  async function handleLogout() {
    google.accounts.id.disableAutoSelect();

    const data = await routes.logout();
    if (data.responseObject.success) {
      setUser({});
    }
  }

  function handleProfileClick() {
    setShowProfileMenu(!showProfileMenu);
  }

  function hideProfileMenu() {
    setShowProfileMenu(false);
  }
  function repeat() {
    gifRef.current!.src = gifWhite;
  }

  async function updateTest() {
    setUpdating(true);
    const response = await routes.updateMongo();
    if (response?.success) {
      adminText.current!.innerHTML = "Klar!";
      setCalendarLoading(true);
    } else {
      adminText.current!.innerHTML = "Något gick fel";
    }
    setTimeout(() => {
      adminText.current!.innerHTML = "Admin";
    }, 2000);
    console.log(response);
    setUpdating(false);
  }

  useEffect(() => {
    if (updating) {
      adminText.current!.innerHTML = "Uppdaterar...";
    }
  }, [updating]);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.profileImgAndLogoDiv}>
        <div className={styles.adminAndProfile}>
          <img
            className={styles.profileImg}
            src={user?.picture}
            onClick={handleProfileClick}
            alt="Profile"
          />
          {user?.role === "admin" && (
            <p ref={adminText} className={styles.adminText}>
              Admin
            </p>
          )}
        </div>
        {showDiv && (
          <img
            className={styles.logo}
            ref={gifRef}
            src={gifWhite}
            onClick={repeat}
            alt="Uptive"
          />
        )}
      </div>
      {showProfileMenu && (
        <div className={styles.background} onClick={hideProfileMenu}>
          <div className={styles.clickOnProfile}>
            <button className={styles.logoutButton} onClick={handleLogout}>
              Logga ut
            </button>
            {user?.role === "admin" && (
              <button
                className={styles.logoutButton}
                onClick={() => updateTest()}
              >
                Uppdatera data från Blikk
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
