import styles from "./LeftArrows.module.css";
import leftarrow from "../../../images/angle-left-solid.svg";
import dubbleleftarrow from "../../../images/angles-left-solid.svg";
import rightarrow from "../../../images/angle-right-solid.svg";
import dubblerightarrow from "../../../images/angles-right-solid.svg";
import { subMonths, startOfMonth, addMonths } from "date-fns/esm";

function LeftArrows(props: any) {
  const { visibleStart, visibleEnd, setVisibleStart, setVisibleEnd } =
    props.props;

  const handleMonthClick = (type: string, length: number) => {
    if (type === "sub") {
      setVisibleStart(subMonths(startOfMonth(visibleStart), length));
      setVisibleEnd(subMonths(startOfMonth(visibleEnd), length));
    }
  };

  return (
    <div style={props.styleProps} className={styles.leftArrowsContainer}>
      <div className={styles.nextButton}>
        <button
          className={styles.button}
          id={styles.doubleArrowLeft}
          onClick={() => handleMonthClick("sub", 12)}
          value={12}
        >
          <img className={styles.images} src={dubbleleftarrow} alt="pil" />
        </button>
        <button
          className={styles.button}
          id={styles.arrowLeft}
          onClick={() => handleMonthClick("sub", 1)}
          value={1}
        >
          <img className={styles.images} src={leftarrow} alt="pil" />
        </button>
      </div>
    </div>
  );
}

export default LeftArrows;
