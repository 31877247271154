import sortAndFilter from "../../../utils/sortAndFilter";
import UpDownArrow from "../../../images/right-left-solid.svg";
import styles from "./SortBy.module.css";

function SortByName(props: any) {
  let { groups, setGroups, reverseName, setReverseName, setReverseDate } =
    props.props;

  function handleChangeName() {
    setGroups(sortAndFilter("Name", groups, reverseName));
    setReverseName(!reverseName);
    setReverseDate(false);
  }

  return (
    <div className={styles.buttonContainer} id={styles.nameSorting}>
      <button className={styles.sortButton} onClick={handleChangeName}>
        <img src={UpDownArrow} alt="pil" className={styles.rotateArrow} />
      </button>
    </div>
  );
}

export default SortByName;
