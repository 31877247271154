import React, { useState } from "react";
import styles from "./Legend.module.css";
import infoImage from "../../../images/info.png";

const Legend = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.imageContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img className={styles.image} alt="Info" src={infoImage} />
      <div
        className={`${styles.modal} ${isHovered ? styles.modalVisible : ""}`}
      >
        <article className={styles.textArea}>
          <div className={styles.green}></div>
          <p className={styles.text}>Uppdrag</p>
        </article>
        <article className={styles.textArea}>
          <div className={styles.stripes}></div>
          <p className={styles.text}> Uppdrag: Tillsvidare</p>
        </article>
        <article className={styles.textArea}>
          <div className={styles.purple}></div>
          <p className={styles.text}>Frånvaro / Ledighet</p>
        </article>
        <article className={styles.textArea}>
          <div className={styles.orange}></div>
          <p className={styles.text}>Pot. uppdrag</p>
        </article>
        <article className={styles.textArea}>
          <div className={styles.star}> *</div>
          <p className={styles.text}> Slutdatum ändrad</p>
        </article>
      </div>
    </div>
  );
};

export default Legend;
