import Footer from "../../layout/Footer/Footer";
import styles from "./Login.module.css";
import GoogleInit from "../../utils/GoogleInit";

function Login() {
  return (
    <div>
      <div className={styles.loginSection}>
        <section className={styles.loginForm}>
          <GoogleInit />
        </section>
      </div>
      <Footer />
    </div>
  );
}
export default Login;
