import styles from "./groupRenderer.module.css";
import editImg from "../../../images/plus-solid.svg";
import defaultPicture from "../../../images/default-profile-picture.jpg";
import { useContext, SyntheticEvent } from "react";
import { AuthContext } from "../../../context/AuthContext";

const GroupRenderer = (props: any) => {
  const { group, isRightSidebar } = props.props;
  if (!isRightSidebar) {
    return LeftSidebarRenderer(group, props.toggleModal);
  } else {
    return rightSidebarRenderer(group);
  }
};

function LeftSidebarRenderer(group: any, toggleModal: Function) {
  const [fistName, lastName] = group.title.split(" ");
  let picture;

  const { user } = useContext(AuthContext);

  if (group?.picture === undefined) {
    picture = defaultPicture;
  } else {
    picture = group?.picture;
  }

  const imageOnError = (event: SyntheticEvent<HTMLImageElement>) => {
    const target = event.currentTarget as HTMLImageElement;
    target.src = defaultPicture;
    target.className = "error";
  };

  return (
    <div className={styles.groupDiv}>
      <img src={picture} alt="" onError={imageOnError} />
      <div className={styles.nameDiv}>
        <p>
          {fistName} {lastName[0]}
        </p>
      </div>
      {user.role === "admin" ? (
        <div
          className={styles.editItem}
          onClick={() => {
            toggleModal({
              id: group.id,
              start_time: new Date(),
              end_time: new Date(),
              title: group.title,
              newItem: true,
            });
          }}
        >
          <img className={styles.editImg} src={editImg} alt="" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function rightSidebarRenderer(group: any) {
  if (group.rightTitle === 0) {
    return (
      <div className={styles.rightSideBar}>
        <p>Inget</p>
        <p>Uppdrag</p>
      </div>
    );
  } else if (typeof group.rightTitle === "number") {
    const formatter = new Intl.DateTimeFormat("se", { month: "short" });
    let year = new Date(group.rightTitle).getFullYear();
    let monthAndDay =
      new Date(group.rightTitle).getDate() +
      " " +
      formatter.format(new Date(group.rightTitle));

    return (
      <div className={styles.rightSideBar}>
        <p>{year}</p>
        <p>{monthAndDay}</p>
      </div>
    );
  } else {
    return <div className={styles.rightSideBar}></div>;
  }
}

export default GroupRenderer;
