import React, { useEffect } from "react";
import { addMonths, subMonths, startOfMonth } from "date-fns/esm";
import minus from "../../../images/magnifying-glass-minus-solid.svg";
import plus from "../../../images/magnifying-glass-plus-solid.svg";
import styles from "./RightArrows.module.css";

function Zoom(props: any) {
  const {
    setVisibleEnd,
    setVisibleStart,
    visibleEnd,
    visibleStart,
    setZoomCount,
    zoomCount,
    setMonthZoom,
    showModal,
    showCustomerModal,
  } = props.props;

  useEffect(() => {
    if (zoomCount >= -3) {
      setMonthZoom("MMM");
    } else if (zoomCount <= -4) {
      setMonthZoom("MM");
    }
  }, [zoomCount, setMonthZoom]);

  useEffect(() => {
    if (!showModal && !showCustomerModal) {
      const listener = (event: KeyboardEvent) => {
        if (event.key === "+" && zoomCount < 3) {
          plusZoomClick();
        } else if (event.key === "-" && zoomCount > -15) {
          minusZoomClick();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [visibleEnd, visibleStart, showModal, zoomCount, showCustomerModal]);

  function minusZoomClick() {
    setZoomCount(zoomCount - 1);
    setVisibleStart(subMonths(startOfMonth(visibleStart), 2));
    setVisibleEnd(addMonths(startOfMonth(visibleEnd), 2));
  }

  function plusZoomClick() {
    setZoomCount(zoomCount + 1);
    setVisibleStart(addMonths(startOfMonth(visibleStart), 2));
    setVisibleEnd(subMonths(startOfMonth(visibleEnd), 2));
  }

  return (
    <div className="zoomContainer">
      <button
        onClick={minusZoomClick}
        className={styles.button}
        disabled={zoomCount <= -15}
        style={{ opacity: zoomCount <= -15 ? 0.5 : 1 }}
      >
        <img src={minus} alt="minus" className={styles.images} />
      </button>
      <button
        onClick={plusZoomClick}
        className={styles.button}
        disabled={zoomCount >= 3}
        style={{ opacity: zoomCount >= 3 ? 0.5 : 1 }}
      >
        <img src={plus} alt="plus" className={styles.images} />
      </button>
    </div>
  );
}

export default Zoom;
