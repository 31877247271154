import "react-calendar-timeline/lib/Timeline.css";
import "./GanttCalendar.css";
import Timeline, {
  TodayMarker,
  TimelineMarkers,
  DateHeader,
  TimelineHeaders,
  SidebarHeader,
} from "react-calendar-timeline";
import { useEffect, useState } from "react";
import useMediaQuery from "../../utils/mediaQuery";
import { startOfDay, startOfMonth } from "date-fns";
import { addMonths, subMonths } from "date-fns/esm";
import GroupRenderer from "./renderer/groupRenderer";
import logoSymbol from "../../images/Logo-animated-White.gif";
import {
  RightArrows,
  LeftArrows,
  SearchBar,
  SortByName,
  SortByDate,
} from "./calendarHeader/HeaderIndex";
import { mapBlikk } from "./calendarItemBlikk";
import Modal from "../modal/Modal";
import CustomerModal from "./calendarHeader/CustomerModal";
import { groupObjects, itemObjects } from "../../utils/types";

let items: itemObjects[];

export default function GanttCalendar(props: any) {
  const { calendarLoading, setCalendarLoading } = props;
  const date = new Date();
  const [modalItem, setModalItem] = useState({});
  const [groups, setGroups] = useState<any>();
  const [unfilteredGroups, setUnfilteredGroups] = useState<groupObjects[]>();
  const [projects, setProjects] = useState<any>([]);

  const [endDate, setEndDate] = useState(date as Date);
  const [startDate, setStartDate] = useState(date as Date);
  const [visibleStart, setVisibleStart] = useState(
    startOfMonth(subMonths(date, 8))
  );
  const [visibleEnd, setVisibleEnd] = useState(
    startOfMonth(addMonths(date, 8))
  );
  const [monthZoom, setMonthZoom] = useState("MMM");
  const [zoomCount, setZoomCount] = useState(0);

  const [reverseName, setReverseName] = useState(true);
  const [reverseDate, setReverseDate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const todayMarker = startOfDay(date).valueOf();

  function toggleModal(item: itemObjects) {
    setModalItem(item);
    setShowModal(!showModal);
  }

  useEffect(() => {
    if (!showModal && !showCustomerModal) {
      const listener = (event: KeyboardEvent) => {
        if (event.key === "ArrowLeft") {
          setVisibleStart(subMonths(startOfMonth(visibleStart), 1));
          setVisibleEnd(subMonths(startOfMonth(visibleEnd), 1));
        } else if (event.key === "ArrowRight") {
          setVisibleStart(addMonths(startOfMonth(visibleStart), 1));
          setVisibleEnd(addMonths(startOfMonth(visibleEnd), 1));
        }
      };

      document.addEventListener("keydown", listener);

      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [visibleStart, visibleEnd, showModal, showCustomerModal]);

  useEffect(() => {
    async function fetchData() {
      const blikkObjects = await mapBlikk(toggleModal);

      setGroups([...blikkObjects.groups]);
      items = blikkObjects.items;
      setUnfilteredGroups(blikkObjects.groups);
      setProjects(blikkObjects.projects);
      setEndDate(date);
      setStartDate(date);

      setCalendarLoading(false);
    }

    if (calendarLoading) {
      fetchData();
    }
  }, [calendarLoading]);

  var mediaQuery = useMediaQuery("(min-width: 768px)");
  var minZoom = 91 * 86400 * 1000;
  var rightSidebarWidth = 70;
  var sidebarWidth = 115;
  var canResize = false;
  var onZoom = false;
  var lineHeight = 38;

  if (!mediaQuery) {
    rightSidebarWidth = 0;
    sidebarWidth = 60;
    canResize = true;
    onZoom = true;
    lineHeight = 28;
  }

  const customerModalProps = {
    showCustomerModal,
    setShowCustomerModal,
  };

  const allProps = {
    visibleStart,
    visibleEnd,
    setVisibleEnd,
    setVisibleStart,
    monthZoom,
    setMonthZoom,
    groups,
    setGroups,
    items,
    unfilteredGroups,
    setZoomCount,
    zoomCount,
    reverseName,
    setReverseName,
    reverseDate,
    setReverseDate,
    toggleModal,
    projects,
    showModal,
  };

  return (
    <>
      {calendarLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logoSymbol}
            alt="Logo"
            className="logo"
            style={{
              width: "10%",
              height: "auto",
            }}
          />
        </div>
      ) : (
        <div className="calendar-wrapper">
          <Modal
            onClose={() => setShowModal(false)}
            show={showModal}
            modalItem={modalItem}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setLoading={setCalendarLoading}
          />
          <CustomerModal
            projects={allProps.projects}
            customerModal={customerModalProps}
          />

          <Timeline
            minZoom={minZoom}
            traditionalZoom={onZoom}
            stackItems
            sidebarWidth={sidebarWidth}
            rightSidebarWidth={rightSidebarWidth}
            buffer={1}
            canMove={false}
            canResize={canResize}
            onItemSelect={undefined}
            horizontalLineClassNamesForGroup={(group: any) =>
              group.department ? [group.department + "-horizontal-row"] : []
            }
            groups={groups}
            groupRenderer={(props) => {
              return <GroupRenderer props={props} toggleModal={toggleModal} />;
            }}
            items={items}
            visibleTimeStart={visibleStart.valueOf()}
            visibleTimeEnd={visibleEnd.valueOf()}
            itemTouchSendsClick={true}
            lineHeight={lineHeight}
            itemHeightRatio={lineHeight / (lineHeight * 1.85)}
          >
            <TimelineMarkers>
              <TodayMarker date={todayMarker}>
                {({ styles, date }) => {
                  styles = {
                    ...styles,
                    width: "2px",
                    backgroundColor: "rgba(10,191,123, 0.6)",
                  };
                  return <div style={styles} />;
                }}
              </TodayMarker>
            </TimelineMarkers>
            <TimelineHeaders className="TimelineHeaders">
              <SidebarHeader>
                {({ getRootProps }) => {
                  const style = getRootProps().style;

                  return (
                    <div>
                      <LeftArrows props={allProps} styleProps={style} />;
                      <SortByName props={allProps} />;
                    </div>
                  );
                }}
              </SidebarHeader>
              <SidebarHeader variant="right">
                {({ getRootProps }) => {
                  const style = getRootProps().style;
                  return (
                    <div>
                      <RightArrows props={allProps} styleProps={style} />;
                      <SortByDate props={allProps} />
                    </div>
                  );
                }}
              </SidebarHeader>
              <SearchBar props={allProps} customModal={customerModalProps} />

              <DateHeader unit="primaryHeader" className={"dateHeaderYear"} />
              <DateHeader
                unit="month"
                labelFormat={monthZoom}
                className={"dateHeaderMonth"}
              />
            </TimelineHeaders>
          </Timeline>
        </div>
      )}
    </>
  );
}
